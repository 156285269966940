<template>
    <el-card>
        <div style="height:6vh">
            <div style="float:left;margin-right:15px;">
                <span class="tip">请选择项目 : &nbsp;&nbsp;&nbsp;</span>
                <el-select class="tip" v-model="project_id" filterable placeholder="请选择项目！" :filter-method="searchProjectList">
                    <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </div>
            <div class="tip" style="float:left; margin-right:10px;">
                <span class="tip">请选择班级 : &nbsp;&nbsp;&nbsp;</span>
                <el-select  class="tip" v-model="class_id" placeholder="请选择班级！" :filter-method="searchClassList" filterable>
                    <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </div>
            <!-- 导出数据按钮 -->
            <!-- <div class="export" @click="exportMiniGameData" > -->
            <div class="export" @click="exportExamPDF">
                <el-button class="themeButton">导出试卷PDF</el-button>
            </div>
        </div>
        <el-table
            :data="taskList"
            stripe
            style="width: 100%">
            <el-table-column
            prop="title"
            label="任务名">
            </el-table-column>
            <el-table-column
            prop="addtime"
            label="创建时间">
            </el-table-column>
            <el-table-column
            label="下载进度">
                <template slot-scope="scope">
                    <el-progress type="circle" :percentage="scope.row.schedule" :width="70"></el-progress>
                    <!-- <div> {{scope.row.schedule}}%</div> -->
                </template>
            </el-table-column>
            <el-table-column
            label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" v-if="scope.row.down_url" @click="down(scope.row.down_url)" >下载</el-button>
                    <el-button disabled v-else>下载</el-button>

                    <el-button type="danger" @click="deletes(scope.row.id)" >删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>
</template>

<style lang="scss">
@import '@/assets/css/manager/other/exportExamPage.scss';
</style>

<script>
import exportExamPage from '@/assets/js/manager/other/exportExamPage.js';
export default {
    ...exportExamPage,
};
</script>
