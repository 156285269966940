import { request } from "@/request/http"; 

export default {
  searchProjectList: (data) => {
    return request("POST", `/index.php/backother/searchProjectList`, data);
  },
  searchClassList: (data) => {
    return request("POST", `/index.php/backother/searchClassList`, data);
  },
  getDownTaskList: (data) => {
    return request("POST", `/index.php/backDownTask/getDownTaskList`, data);
  },
  exportExamPDF: (data) => {
    return request("POST", `/index.php/backother/exportExamPDF`, data);
  },
  deleteExamPDFData: (data) => {
    return request("POST", `/index.php/backother/deleteExamPDFData`, data);
  },

}