import { Message } from 'element-ui';
import API from '@/api/manager/other/exportExamPage.js';
import { nprogressStart, nprogressClose } from '@/plugins/nprogress.js';
export default {
  name: 'statistics',
  inject: ['reload'],
  components: {},
  data() {
    return {
      project_id: '',
      projectList: '',
      class_id: '',
      classList: [],
      taskList: [],
      intervalId:'',
      downSign:false,
    };
  },
  created() 
  {
    let self = this;
    self.getData();
    self.intervalId = setInterval(function() 
    {
      if(self.downSign == false)
      {
        self.getData();
      }
    }, 5000);
  },
  destroyed() {
    clearInterval(intervalId );
  },
  methods: 
  {
    deletes(id){
      let self = this;
      // 删除请求
      self.$confirm('确认要删除这条数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: id,
        };
        API.deleteExamPDFData(data)
        .then((result)=>{
            if(result.code > 0 )
            {
                self.getData();
                this.$Tips({
                    // 消息提示内容
                    message:result.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
            }
        })  
      }).catch(() => {
          // Message.info('已取消');        
      });
    },
    down(url)
    {
      window.open(url);
    },
    exportExamPDF()
    {
      let self = this;
      let data = {
        project_id: self.project_id,
        class_id: self.class_id,
      };
      self.downSign = true;
      nprogressStart();
      self.$Loading.show();
      API.exportExamPDF(data)
      .then((result)=>{
        self.$Tips({
          // 消息提示内容
          message:result.msg,
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'success',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          displayTime:1500
        })
        if(result.code > 0 )
        {
          self.downSign = false;
          self.project_id = '';
          self.class_id = '';
          self.getData();
        }
        self.$Loading.hide();
        nprogressClose();
      })
    },
    searchProjectList(e) 
    {
      let self = this;
      if (self.searchValue == '') 
      {
        return;
      }
      let data = {
        text: e,
      };
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      API.searchProjectList(data)
      .then((result)=>{
        console.log(result)
        if(result.code > 0 )
        {
            self.projectList = result.list;
        }
        else
        {
            Message.error('系统错误')
        }
        self.$Loading.hide();
        nprogressClose();
      })
    },
    searchClassList(e) 
    {
      let self = this;
      if (self.searchValue == '') 
      {
        return;
      }
      let data = {
        text: e,
      };
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      API.searchClassList(data)
      .then((result)=>{
        console.log(result)
        if(result.code > 0 )
        {
            self.classList = result.list;
        }
        else
        {
            Message.error('系统错误')
        }
        self.$Loading.hide();
        nprogressClose();
      })
    },
    getData() 
    {
      let self = this;
      if (self.searchValue == '') 
      {
        return;
      }
      let data = {
        down_type: 2,
      };
      // 开启loading
      // nprogressStart();
      // self.$Loading.show();
      API.getDownTaskList(data)
      .then((result)=>{
        // console.log(result)
        if(result.code > 0 )
        {
            self.taskList = result.list;
        }
        else
        {
            Message.error('系统错误')
        }
        // self.$Loading.hide();
        // nprogressClose();
      })
    },
  },
};
